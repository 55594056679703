<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  getOderList,
  getOderListSummary,
  reportGetOrderInfo, // 明细查询
  reportGetOrderInfoSummary, // 明细查询汇总
  reportGetOrderDateList, // 裱花订单按日期
  reportGetOrderDateListSummary, // 裱花订单按日期汇总
  reportGetOrderShopList, // 裱花订单按门店汇总
  reportGetOrderShopListSummary, // 裱花订单按门店汇总
  reportGetOrderGoodsList, // 裱花订单按商品汇总
  reportGetOrderGoodsListSummary, // 裱花订单按商品汇总

  // orderListAPI,
  // orderListSummaryAPI,
  // getShopPredetermineDateList,
  // getShopPredetermineDateListSummary,
  // getShopPredetermineShopList,
  // getShopPredetermineShopListSummary,
  // getShopPredetermineGoodsList,
  // getShopPredetermineGoodsListSummary
} from "@/api/decoration/report/orderQuery"; // 销售列表
import { getDicts } from "@/api/system/dict/data";
export default {
  name: "PosReserveQuery",
  dicts: ["fetch_type", "order_status"],
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "裱花订单查询",
        rowKey: "billIda",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "receiverNames", label: "收货人" },
              { filter: "receiverPhones", label: "收货人电话" },
            ],
          },
          {
            label: "取货日期",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({
              pickupBeginBillDate: a,
              pickupEndBillDate: b,
            }),
          },
          {
            type: "local",
            label: "状态",
            model: "",
            filter: "orderStatuss",
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: "order_status",
              label: "dictLabel",
              value: "dictValue",
            },
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "predetermineShopIds",
              label: "预定门店",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择预定门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              label: "取货门店",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择取货门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "local",
            label: "配送模式",
            model: "",
            filter: "fetchTypes",
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              remote: getDicts,
              remoteBody: "fetch_type",
              label: "dictLabel",
              value: "dictValue",
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        tabsColumns: [
          {
            defaultBody: { searchType: "detail" },
            getListApi: reportGetOrderInfo,
            getSummaryApi: reportGetOrderInfoSummary,
            uuid: "1dc906f2-e80a-bad8-7739-63653dfe30c4",
            title: "裱花订单明细查询",
            type: "detail",
            exportOption: {
              fastExportUrl:
                "/api/system/decoration/order/report/getOrderDetailListExport",
              exportName: "裱花订单明细查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "商品规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
              },
              {
                prop: "unitPrice",
                label: "单价",
                minWidth: 150,
              },
              {
                prop: "saleMoney",
                label: "金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "machining",
                label: "加值属性",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "fetchShopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverRegionAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "pickUpBillPosNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "fetchDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "pickUpDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "billRemark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ["unitQty", "saleMoney"],
          },
          {
            defaultBody: { searchType: "summary" },
            getListApi: getOderList,
            getSummaryApi: getOderListSummary,
            uuid: "c71776a4-bcab-bec5-fdca-c9a646286c1a",
            title: "裱花订单查询",
            type: "summary",
            exportOption: {
              fastExportUrl: "/api/system/decoration/order/report/getOrderListExport",
              exportName: "裱花订单查询",
            },
            columns: [
              {
                prop: "billDate",
                label: "预订时间",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "预订小票号",
                type: "link",
                click: "update",
                minWidth: 180,
              },
              {
                prop: "receiverName",
                label: "收货人/客户名称",
                minWidth: 150,
              },
              {
                prop: "receiverPhone",
                label: "联系电话",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 150,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "fetchTypeName",
                label: "配送/取货方式",
                minWidth: 150,
              },
              {
                prop: "fetchShopName",
                label: "配送/取货门店",
                minWidth: 150,
              },
              {
                prop: "receiverRegionAddress",
                label: "收货地址",
                minWidth: 150,
              },
              {
                prop: "predetermineShopName",
                label: "预定门店",
                minWidth: 150,
              },
              {
                prop: "produceDeptName",
                label: "生产部门",
                minWidth: 150,
              },
              {
                prop: "pickUpBillPosNo",
                label: "取货小票号",
                minWidth: 180,
              },
              {
                prop: "fetchDate",
                label: "配送/取货时间",
                minWidth: 200,
              },
              {
                prop: "pickUpDate",
                label: "实际取货时间",
                minWidth: 155,
              },
              {
                prop: "orderStatusName",
                label: "状态",
                minWidth: 120,
              },
              {
                prop: "billRemark",
                label: "备注",
                minWidth: 150,
              },
            ],
            summary: ["saleMoney"],
          },
          {
            getListApi: reportGetOrderDateList,
            getSummaryApi: reportGetOrderDateListSummary,
            uuid: "a6c1a36a-3b9e-637a-3342-77ad31f7756c",
            title: "裱花查询汇总（按日期）",
            type: "date",
            exportOption: {
              fastExportUrl: "/api/system/decoration/order/report/getOrderDateListExport",
              exportName: "裱花查询汇总（按日期）",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: reportGetOrderShopList,
            getSummaryApi: reportGetOrderShopListSummary,
            uuid: "4da2253a-1f02-06f4-bbd0-2bd525c6348f",
            title: "裱花查询汇总（按门店）",
            type: "shop",
            exportOption: {
              fastExportUrl: "/api/system/decoration/order/report/getOrderShopListExport",
              exportName: "裱花查询汇总（按门店）",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 155,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum"],
          },
          {
            // defaultBody: { searchType: 'refund' },
            getListApi: reportGetOrderGoodsList,
            getSummaryApi: reportGetOrderGoodsListSummary,
            uuid: "f40a789a-f5bd-736f-0ff6-3fdad3ac47b5",
            title: "裱花查询汇总（按商品）",
            type: "shop",
            exportOption: {
              fastExportUrl:
                "/api/system/decoration/order/report/getOrderGoodsListExport",
              exportName: "裱花查询汇总（按商品）",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 155,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 155,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 155,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 155,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 155,
              },
              {
                prop: "orderQty",
                label: "数量",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "orderMoney",
                label: "金额",
                minWidth: 155,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 155,
                sortable: true,
              },
            ],
            summary: ["orderQty", "orderMoney", "agioMoneySum"],
          },
        ],
      },
      payTypes: [], //付款方式
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          this.$router.push({
            name: "posReserveQueryDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
